import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { ValidateFormUser } from 'Validate/ValidateForm'
import {
    FormGroup,
    Label,
    Input,
    Button
} from "reactstrap";
import moment from 'moment'

import UserService from '../../services/user.service';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import './CSS.css'

function UserDetails(props) {
    const data = props.data
    const [id, setId] = useState(data.id || '')
    const [account, setAccount] = useState(data.username || '')
    const [email, setEmail] = useState(data.email || '')
    const [fullName, setFullName] = useState(data.fullname || '')
    const [drive, setDrive] = useState(data.drive || '')
    const [language, setLanguage] = useState(data.language || '')
    const [password, setPassword] = useState('')
    const [expired, setExpired] = useState(moment(data.expired).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'))
    const [activeCalendar, setActiveCalendar] = useState(false)
    const [loai, setLoai] = useState(data.version || 'Thường')
    const [showloai, setShowload] = useState(false)
    const [calendar, setCalendar] = useState(new Date());
    //const [passwordEncryption, setPasswordEncryption] = useState(data.password || '')
    const PATH = props.path

    const optionsLanguage = [
        { value: 'Vietnamese', label: 'Vietnamese' },
        { value: 'English', label: 'English' },
    ];

    const optionsLoai = [
        { value: 'Thường', label: 'Thường' },
        { value: 'Pro', label: 'Pro' },
    ];

    useEffect(() => {
        console.log(data)

        if (data.version == "Thường" || data.version == "Pro") setShowload(true)
        window.scrollTo(0, 0);
    }, [])

    function deleteUser() {
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có chắc chắn muốn xóa tài khoản này ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không'
        }).then((result) => {
            if (result.value) {
                UserService.deleteUserByUserId(PATH, id)
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire({
                                icon: 'success',
                                title: response.data.message,
                                showConfirmButton: false,
                                timer: 1500
                            }).then(res => {
                                window.location.reload()
                            })
                        } else {
                            swalWithBootstrapButtons.fire(
                                'Thất bại !',
                                response.data.message,
                                'error'
                            )
                        }
                    }, error => {
                        swalWithBootstrapButtons.fire(
                            'Thất bại !',
                            error,
                            'error'
                        )
                    })
            };
        })
    }

    function userInformationWillUpdate() {
        const date = moment(expired, 'DD-MM-YYYY')
        var v_loai = null
        if (showloai) {
            v_loai = loai
        }
        return {
            username: account,
            password: changePassword(password),
            email: email,
            fullname: fullName,
            language: language,
            drive: drive,
            expired: expired,
            loai: v_loai
        }
    }

    function changePassword(pass) {
        return pass.trim() == '' ? '' : pass
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    function updateUser() {
        const validate = ValidateFormUser(userInformationWillUpdate(), expired)
        if (validate) {
            return
        }

        UserService.updateUserByUserId(PATH, id, userInformationWillUpdate())
            .then(result => {
                if (result.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Sửa thành công',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(res => {
                        window.location.reload()
                    })

                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }
            }, error => {
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function selectACalendar(value) {
        setCalendar(value)

        const date = new Date(value)
        const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        setExpired(newDate)
        setActiveCalendar(false)
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}>

            <div style={{ width: "100vh", height: "100vh" }}>
                <FormGroup>
                    <Label style={{ marginTop: "1rem" }} for="email">Email</Label>
                    <Input
                        value={email}
                        id="email"
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />

                    <Label style={{ marginTop: "1rem" }} for="account">Tài khoản</Label>
                    <Input
                        value={account}
                        id="account"
                        onChange={(e) => setAccount(e.target.value)}
                    />
                    <Label style={{ marginTop: "1rem" }} for="password">Mật khẩu</Label>
                    <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "0px" }}>
                        <Input
                            style={{ height: "38px", marginTop: "10px" }}
                            value={password}
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-info" type="button" onClick={() => setPassword(Math.random().toString(36).slice(-8))}>Ngẫu nhiên</button>
                        </div>
                    </div>

                    <Label style={{ marginTop: "1rem" }} for="fullname">Họ tên</Label>
                    <Input
                        value={fullName}
                        id="fullname"
                        onChange={(e) => setFullName(e.target.value)}
                    />

                    <Label style={{ marginTop: "1rem" }} for="language">Ngôn ngữ</Label>
                    <Select
                        className="dropdown-select-index-1"
                        placeholder={language}
                        value={language}
                        onChange={(e) => setLanguage(e.value)}
                        options={optionsLanguage}
                    />

                    {
                        showloai ?
                            <>
                                <Label style={{ marginTop: "1rem" }} for="loai">Loại</Label>
                                <Select
                                    className="dropdown-select-index-2"
                                    placeholder={loai}
                                    value={loai}
                                    onChange={(e) => setLoai(e.value)}
                                    options={optionsLoai}
                                />

                            </>
                            :
                            null
                    }

                    <Label style={{ marginTop: "1rem" }} for="drive">Mã máy</Label>
                    <Input
                        className="dropdown-select-index-3"
                        value={drive}
                        id="drive"
                        onChange={(e) => setDrive(e.target.value)}
                    />
                    <Label style={{ marginTop: "1rem" }} for="expired">Hạn sử dụng</Label>

                    <Input
                        className="d-block d-sm-none"
                        type="date"
                        onChange={(e) => setExpired(e.target.value)}
                        value={expired}
                        id="expired"
                    />

                    <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "0px" }}>
                        <Input
                            className="d-none d-sm-block"
                            style={{ height: "38px", marginTop: "10px" }}
                            onClick={() => {
                                if (activeCalendar) {
                                    setActiveCalendar(false)
                                } else {
                                    setActiveCalendar(true)
                                }
                            }}
                            autocomplete="off"
                            onChange={(e) => setExpired(e.target.value)}
                            value={expired}
                            id="expired"
                        />
                        <div className="input-group-append">
                            <button className="btn btn-info" type="button" onClick={() => {
                                const date = new Date(expired)
                                setExpired(moment(date.setMonth(date.getMonth() + 1)).format('YYYY-MM-DD'))
                            }}>+1 Tháng</button>

                            <button className="btn btn-info" type="button" onClick={() => {
                                const date = new Date(expired)
                                setExpired(moment(date.setMonth(date.getMonth() + 3)).format('YYYY-MM-DD'))
                            }}>+3 Tháng</button>

                            <button className="btn btn-info" type="button" onClick={() => {
                                const date = new Date(expired)
                                setExpired(moment(date.setFullYear(date.getFullYear() + 1)).format('YYYY-MM-DD'))
                            }}>+1 Năm</button>
                            <button className="btn btn-info" type="button" onClick={() => {
                                const date = new Date(expired)
                                setExpired(moment(date.setFullYear(date.getFullYear() + 10)).format('YYYY-MM-DD'))
                            }}>Vĩnh viễn</button>
                        </div>
                    </div>
                </FormGroup>

                <div style={{ marginTop: "20px" }}>
                    <Button color="primary" style={{ marginRight: "10px" }} onClick={() => updateUser()}>Sửa tài khoản</Button>
                    <Button color="danger" onClick={() => deleteUser()}>Xóa tài khoản</Button>
                </div>


                <div style={{ float: "right", marginTop: "0.5rem" }}>
                    <div style={{ width: "100%" }}></div>
                    {/* <button className="btn btn-primary" onClick={() => Login()}>Đăng nhập</button> */}
                </div>
            </div>
        </div>
    )
}

export default UserDetails