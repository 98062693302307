import React, { useRef, useState, useEffect } from "react"
import NotificationAlert from 'react-notification-alert';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { ValidateFormUser } from 'Validate/ValidateForm'
import moment from 'moment'
import {
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    Div,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
// import { Button, Modal } from 'react-bootstrap';

import UserService from '../../services/user.service.v3';
import ToolService from '../../services/tool.service';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import './CSS.css'



Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function CreateUser(props) {

    const [account, setAccount] = useState('')
    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [language, setLanguage] = useState('Vietnamese')
    const [drive, setDrive] = useState(1)
    const [password, setPassword] = useState(Math.random().toString(36).slice(-8))
    const [expired, setExpired] = useState(moment(new Date()).format('YYYY-MM-DD'))
    const [activeCalendar, setActiveCalendar] = useState(false)
    const [calendar, setCalendar] = useState(new Date());
    const [message, setMessage] = useState('')

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);


    const [showload, setShowload] = useState(false)
    const [showloadSendMail, setShowloadSendMail] = useState(false)

    const [showCopy, setShowCopy] = useState(false)
    const [showCopyEmail, setShowCopyEmail] = useState(false)

    const [optionsThoiHan, setOptionsThoiHan] = useState([])
    const [thoiHan, setThoiHan] = useState(null)

    const [optionLoai, setOptionLoai] = useState([])
    const [loai, setLoai] = useState(null)

    const PATH = props.path

    const [user, setUser] = useState(null)
    const back = (data) => props.changePage('', 'add', user);

    useEffect(() => {
        ToolService.getAttribute(PATH).then((result) => {
            result.data.forEach(item => {
                if (item.name.toLowerCase() == "thời hạn") {
                    setOptionsThoiHan(item.options)
                    setThoiHan(item.options[0])
                    setExpired(moment(new Date().addDays(item.options[0].exp)).format('YYYY-MM-DD'))
                } else if (item.name.toLowerCase() == "loại") {
                    setOptionLoai(item.options)
                    setLoai(item.options[0])
                }
            });
        })
    }, [])


    function optionsMaMay() {
        var opt = []
        for (var i = 1; i < 16; i++) {
            opt.push({ value: i, label: i })
        }
        return opt
    }

    const optionsLanguage = [
        { value: 'Vietnamese', label: 'Vietnamese' },
        { value: 'English', label: 'English' },
    ];

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })



    function selectACalendar(value) {
        setCalendar(value)

        const date = new Date(value)
        const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        setExpired(newDate)
        setActiveCalendar(false)
    }

    function createUser() {
        const validate = ValidateFormUser(userInformationWillCreate(), expired)
        if (validate) {
            return
        }

        setShowload(true)
        UserService.createUser(PATH, userInformationWillCreate())
            .then((result) => {
                setShowload(false)

                if (result.data.success) {
                    setMessage(`Username : ${account} \nPassword : ${password} \n \nPass unrar : ${result.data.passunrar}  \n \nMọi chi tiết liên hệ : https://facebook.com/dhbtools \nWebsite : https://dhbtools.com`)
                    Swal.fire({
                        icon: 'success',
                        title: 'Tạo tài khoản thành công',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(res => {
                        setUser(result.data.user)
                        setModal(true)
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }

            }, error => {
                setShowload(false)
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function createUserMail() {

        const validate = ValidateFormUser(userInformationWillCreate(), expired)
        if (validate) {
            return
        }
        setShowloadSendMail(true)

        UserService.createUserMail(PATH, userInformationWillCreate())
            .then((result) => {
                setShowloadSendMail(false)

                if (result.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Tạo tài khoản và gửi email thành công',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(res => {
                        window.location.reload()
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }

            }, error => {
                setShowloadSendMail(false)
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function userInformationWillCreate() {

        console.log(drive)

        const date = moment(expired, 'YYYY-MM-DD')

        var _loai = ''
        try {
            _loai = loai.value
        } catch (error) {
        }

        var _thoihan = ''
        try {
            _thoihan = thoiHan.value
        } catch (error) {
        }

        return {
            "username": account,
            "password": password,
            "email": email,
            "fullname": fullName,
            "language": language,
            "drive": drive,
            "expired": date.format('YYYY-MM-DD'),
            "thoihan": _thoihan,
            "loai": _loai,
        }
    }


    const style = {
        maxHeight: '230px',
        minHeight: '230px',
        resize: 'none',
        padding: '9px',
        boxSizing: 'border-box',
        fontSize: '15px',
        marginTop: '25px'
    };

    function copyToClipboard() {
        setShowCopy(true)
        var textField = document.createElement('textarea')
        textField.value = message
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    function copyEmailToClipboard() {
        setShowCopyEmail(true)
        var textField = document.createElement('textarea')
        textField.value = email
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }




    return (
        <div className="content">



            <Button className='btn btn-primary' style={{ marginRight: "10px" }} onClick={toggle}>Tạo tài khoản {showload ? <span class="spinner-border spinner-border-sm"></span> : null}  </Button>


            <Row>
                <Col lg={{ size: 6, offset: 3 }} md={{ size: 6, offset: 3 }} sm={{ size: 6, offset: 3 }}>
                    <Card style={{ padding: '15px' }} >
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Tạo tài khoản</Label>
                        </CardHeader>

                        <CardBody>
                            <FormGroup>
                                <Label style={{ marginTop: "1rem" }} for="email">Email</Label>
                                <Input
                                    value={email}
                                    id="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value)

                                        var user = e.target.value
                                        if (user.includes("@")) {
                                            var arr = user.split('@')
                                            setAccount(arr[0])
                                        } else {
                                            setAccount(user)
                                        }

                                    }}
                                />
                                <Label style={{ marginTop: "1rem" }} for="account">Tài khoản</Label>
                                <Input
                                    value={account}
                                    id="account"
                                    onChange={(e) => setAccount(e.target.value)}
                                />

                                <Label style={{ marginTop: "1rem" }} for="password">Mật khẩu</Label>
                                <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "-10px" }}>
                                    <Input
                                        style={{ height: "38px", marginTop: "10px" }}
                                        value={password}
                                        id="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-info" type="button" onClick={() => setPassword(Math.random().toString(36).slice(-8))}>Ngẫu nhiên</button>
                                    </div>
                                </div>

                                <Label style={{ marginTop: "1rem" }} for="fullname">Họ tên</Label>
                                <Input
                                    value={fullName}
                                    id="fullname"
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                <Label style={{ marginTop: "1rem" }} for="language">Ngôn ngữ</Label>
                                <div>
                                    <Select
                                        className="dropdown-select-index-1"
                                        isSearchable={false}
                                        placeholder={language}
                                        value={language}
                                        onChange={(e) => setLanguage(e.value)}
                                        options={optionsLanguage}
                                    />
                                </div>

                                {
                                    thoiHan
                                        ?
                                        <>
                                            <Label style={{ marginTop: "1rem" }} for="drive">Thời hạn</Label>
                                            <div>
                                                <Select
                                                    className="dropdown-select-index-2"
                                                    isSearchable={false}
                                                    placeholder={thoiHan}
                                                    value={thoiHan}
                                                    onChange={(e) => {
                                                        setExpired(moment(new Date().addDays(e.exp)).format('YYYY-MM-DD'))
                                                        setThoiHan(e)

                                                        if (e.value.includes("10")) {
                                                            setDrive(10)
                                                        } else {
                                                            setDrive(1)
                                                        }
                                                    }}
                                                    options={optionsThoiHan}
                                                />
                                            </div>
                                        </>
                                        :
                                        null
                                }

                                {
                                    loai
                                        ?
                                        <>
                                            <Label style={{ marginTop: "1rem" }} for="drive">Loại</Label>
                                            <div>
                                                <Select
                                                    className="dropdown-select-index-3"
                                                    isSearchable={false}
                                                    placeholder={loai}
                                                    value={loai}
                                                    onChange={(e) => {
                                                        if (PATH == "dhbrender") {
                                                            if (e.value == "Dùng thử") {
                                                                setExpired(moment(new Date().addDays(7)).format('YYYY-MM-DD'))
                                                            } else {
                                                                setExpired(moment(new Date()).format('YYYY-MM-DD'))
                                                            }
                                                        }

                                                        setLoai(e)
                                                    }}
                                                    options={optionLoai}
                                                />
                                            </div>
                                        </>
                                        :
                                        null
                                }



                                <Label style={{ marginTop: "1rem" }} for="drive">Số lượng thiết bị</Label>
                                <Select
                                    className="dropdown-select-index-4"
                                    isSearchable={false}
                                    placeholder={drive}
                                    value={drive}
                                    onChange={(e) => setDrive(e.value)}
                                    options={optionsMaMay()}
                                />


                                <Label style={{ marginTop: "1rem" }} for="expired">Ngày hết hạn</Label>
                                <Input
                                    className="d-block d-sm-none"
                                    type="date"
                                    onChange={(e) => setExpired(e.target.value)}
                                    value={expired}
                                    id="expired"
                                    style={{ marginTop: "-10px" }}
                                />

                                <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "-10px" }}>
                                    <Input
                                        className="d-none d-sm-block"
                                        style={{ height: "38px", marginTop: "10px" }}
                                        onClick={() => {
                                            if (activeCalendar) {
                                                setActiveCalendar(false)
                                            } else {
                                                setActiveCalendar(true)
                                            }
                                        }}
                                        onChange={(e) => setExpired(e.target.value)}
                                        value={expired}
                                        id="expired"
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setMonth(date.getMonth() + 1)).format('YYYY-MM-DD'))
                                        }}>+1 Tháng</button>

                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setMonth(date.getMonth() + 3)).format('YYYY-MM-DD'))
                                        }}>+3 Tháng</button>

                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setFullYear(date.getFullYear() + 1)).format('YYYY-MM-DD'))
                                        }}>+1 Năm</button>

                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setFullYear(date.getFullYear() + 10)).format('YYYY-MM-DD'))
                                        }}>Vĩnh viễn</button>
                                    </div>
                                </div>

                                <div style={{ marginTop: "10px" }}>
                                    {
                                        activeCalendar
                                            ?
                                            <>
                                                <Calendar
                                                    onChange={(e) => { selectACalendar(e) }}
                                                    value={calendar}
                                                />
                                            </>
                                            :
                                            null
                                    }
                                </div>
                            </FormGroup>
                        </CardBody>

                        <CardFooter style={{ marginTop: "10px" }}>
                            <Button className='btn btn-primary' style={{ marginRight: "10px" }} onClick={() => createUser()}>Tạo tài khoản {showload ? <span class="spinner-border spinner-border-sm"></span> : null}  </Button>
                            <Button className='btn btn-success' style={{ marginRight: "10px" }} onClick={() => createUserMail()}>Tạo và gửi mail {showloadSendMail ? <span class="spinner-border spinner-border-sm"></span> : null}  </Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>


            <div>
                <Modal isOpen={modal} toggle={toggle} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90%'
                }}>
                    <ModalHeader toggle={toggle}>
                        <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Tạo thành công</Label>
                    </ModalHeader>

                    <ModalBody style={{ marginBottom: "20px" }}>
                        <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "0px" }}>
                            <Input
                                style={{ height: "38px", marginTop: "10px" }}
                                value={email}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-info" type="button" onClick={copyEmailToClipboard}>{
                                    showCopyEmail
                                        ?
                                        <>
                                            Đã sao chép
                                        </>
                                        :
                                        <>
                                            Sao chép
                                        </>
                                }</button>
                            </div>
                        </div>
                        <textarea rows="50" style={style} className="form-control" value={message} />
                    </ModalBody>

                    <ModalFooter>

                        <Button onClick={copyToClipboard} className="btn btn-info" style={{ marginRight: "10px" }} >
                            {
                                showCopy
                                    ?
                                    <>
                                        Đã sao chép
                                    </>
                                    :
                                    <>
                                        Sao chép
                                    </>
                            }
                        </Button>
                        <Button onClick={() => {
                            toggle();
                            back();
                        }} className="btn btn-danger" >Đóng</Button>
                    </ModalFooter>
                </Modal>
            </div>

        </div>
    )
}

export default CreateUser