import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/admin/";

class HistoryService {

  getHistory() {
    return axios.get(API_URL + "history", { headers: authHeader() });
  }

}

export default new HistoryService();
