import axios from 'axios';
import Deco from "security/deco"
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/admin/update/";

class UpdateService {

    getUpdate(tool) {
        return axios.get(API_URL + tool, { headers: authHeader() });
    }

    postUpdate(tool, version, log, selectedFile) {
        var fs = require('fs').promises;
        var formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("version", version);
        formData.append("log", log);

        //Authen
        const user = JSON.parse(Deco(localStorage.getItem('user')))
        if (user && user.token) {
            try {
                var data = {
                    method: "post",
                    url: API_URL + tool,
                    data: formData,
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": 'Bearer ' + user.token
                    }
                }

                return axios(data);

            } catch (error) {
                console.log(error)
            }
        }
    }

    putUpdate(id, tool, version, log, selectedFile) {
        if (selectedFile) {
            var fs = require('fs').promises;
            var formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("version", version);
            formData.append("log", log);

            //Authen
            const user = JSON.parse(Deco(localStorage.getItem('user')))
            if (user && user.token) {
                try {
                    var data = {
                        method: "put",
                        url: API_URL + tool + '/' + id,
                        data: formData,
                        headers: {
                            "Content-Type": "multipart/form-data",
                            "Authorization": 'Bearer ' + user.token
                        }
                    }

                    return axios(data);

                } catch (error) {
                    console.log(error)
                }
            }
        } else {
            var data = {
                method: "put",
                url: API_URL + tool + '/' + id,
                data: {
                    version: version,
                    log: log
                },
                headers: authHeader()
            }

            return axios(data);
        }
    }

    deleteUpdate(tool, id, filename) {
        return axios.delete(API_URL + tool + '/' + id + '?filename=' + filename, { headers: authHeader() });
    }
}

export default new UpdateService();
