import React, { useState, useEffect } from 'react'
import {
    Table,
    Input,
    Button,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Col,
    Row,
    Card,
    CardFooter,
    CardBody,
    Label,
    FormGroup,
    CardHeader,
    CardTitle
} from "reactstrap";
import moment from 'moment'
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import updateService from 'services/update.service'


function Orders() {
    const [tool, setTool] = useState('dhbreupfacebook')
    const [toolName, setToolName] = useState('DHB Reup Facebook')
    const [update, setUpdate] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [id, setId] = useState('')
    const [version, setVersion] = useState('')
    const [log, setLog] = useState('')
    const [selectedFile, setSelectedFile] = useState('')



    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        loadUpdate("dhbreupfacebook")
    }, [])

    function loadUpdate(tool) {
        setTool(tool)

        updateService.getUpdate(tool).then((result) => {
            setUpdate(result.data.data)
        })
    }

    function postUpdate() {
        updateService.postUpdate(tool, version, log, selectedFile).then((result) => {
            console.log(result.data)

            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                showConfirmButton: true,
                timer: 1000
            }).then(res => {
                window.location.reload()
            })
        })
    }

    function putUpdate() {
        updateService.putUpdate(id, tool, version, log, selectedFile).then((result) => {
            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                showConfirmButton: true,
                timer: 1000
            }).then(res => {
                window.location.reload()
            })
        })
    }

    function table() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th className="table-device-mobile">Version</th>
                        <th className="table-device-mobile">Updated At</th>
                        <th className="table-device-mobile">Filename</th>
                        <th className="table-device-mobile">Log</th>
                        <th className='text-center'>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }

    function toAddNew() {
        var val = {}
        val.tool = tool
        // setDataProps(val)
        // setPage('detail')
    }

    function toDetailView(val) {
        setVersion(val.version)
        setLog(val.log)
        setId(val.id)
    }

    function loadFillData() {
        return update.map((data, key) => {
            return (
                <tr onClick={() => toDetailView(data)} style={{ cursor: "pointer" }} key={key}>
                    <td className="table-device-mobile">{data.version}</td>
                    <td className="table-device-mobile"> {moment(data.createdAt).format('DD/MM/yyyy HH:mm')} </td>
                    <td className="table-device-mobile">{data.url_v2}</td>
                    <td className="table-device-mobile" style={{ whiteSpace: "pre-line", lineHeight: "1.5" }} >{data.log}</td>

                    <td className='text-center'>
                        <Button style={{ marginLeft: "10px" }} type="button" className="btn btn-danger " onClick={() => {

                            Swal.fire({
                                title: 'Xác nhận xóa ?',
                                text: `Điều này sẽ xóa bản cập nhập ${data.version} phần mềm ${toolName}`,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#3085d6',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'Có, xóa bản cập nhập!'
                            }).then((result) => {
                                if (result.isConfirmed) {

                                    updateService.deleteUpdate(data.tool, data.id, data.url_v2).then((result) => {
                                        Swal.fire({
                                            icon: result.data.success ? 'success' : 'error',
                                            title: result.data.message,
                                            showConfirmButton: true,
                                            timer: 1000
                                        }).then(res => {
                                            window.location.reload()
                                        })
                                    })
                                }
                            })
                        }}>Xóa</Button>
                    </td>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            <Row>
                <Col md="9">
                    <div className="card">
                        <div className="card-header">
                            <div className="p-2">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                    <DropdownToggle className="btn-secondary" caret>{toolName}</DropdownToggle>
                                    <DropdownMenu >
                                        <DropdownItem onClick={() => { loadUpdate('dhbreupyoutube'); setToolName("DHB Reup Youtube") }} >Reup Youtube</DropdownItem>
                                        <DropdownItem onClick={() => { loadUpdate('dhbreupfacebook'); setToolName("DHB Reup Facebook") }} >Reup Facebook</DropdownItem>
                                        <DropdownItem onClick={() => { loadUpdate('dhbreuptiktok'); setToolName("DHB Reup TikTok") }} >Reup TikTok</DropdownItem>
                                        <DropdownItem onClick={() => { loadUpdate('dhbreupinstagram'); setToolName("DHB Reup Instagram") }} >Reup Instagram</DropdownItem>
                                        <DropdownItem onClick={() => { loadUpdate('dhbstreampro'); setToolName("DHB Stream Pro") }} >Stream Pro</DropdownItem>
                                        <DropdownItem onClick={() => { loadUpdate('dhbstreamfacebook'); setToolName("DHB Stream Facebook") }} >Stream Facebook</DropdownItem>
                                        <DropdownItem onClick={() => { loadUpdate('dhbyoutubemanager'); setToolName("DHB Youtube Manager") }} >Youtube Manager</DropdownItem>
                                        <DropdownItem onClick={() => { loadUpdate('dhbgologinmanager'); setToolName("DHB GoLogin Manager") }} >GoLogin Manager</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="card-body">
                            {table()}
                        </div>
                    </div>
                </Col>

                <Col md="3">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5">Thông tin</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label >Version</Label>
                                <Input
                                    value={version}
                                    id="version"
                                    onChange={(e) => { setVersion(e.target.value) }}
                                />

                                <Label style={{ marginTop: "1rem" }}>Log</Label>
                                <Input style={{ maxHeight: "100%" }}
                                    type="textarea"
                                    rows="5"
                                    value={log}
                                    id="log"
                                    onChange={(e) => { setLog(e.target.value) }}
                                />

                                <Label style={{ marginTop: "1rem" }}>File</Label>
                                <Input
                                    type="file"
                                    id="file"
                                    onChange={(e) => { setSelectedFile(e.target.files[0]) }}
                                />
                            </FormGroup>
                        </CardBody>

                        <CardFooter>
                            <Button
                                disabled={!(version && log && selectedFile)}
                                type="button"
                                className="btn btn-success"
                                onClick={postUpdate}
                            >
                                Thêm mới
                            </Button>


                            <Button
                                disabled={!(id && version && log)}
                                style={{ marginLeft: "10px" }}
                                type="button"
                                className="btn btn-warning"
                                onClick={putUpdate}
                            >
                                Cập nhập
                            </Button>

                        </CardFooter>
                    </Card>
                </Col>
            </Row>

        </div>
    )
}

export default Orders