import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/admin";

class ToolService {

  getTool() {
    return axios.get(API_URL + "/tool", { headers: authHeader() });
  }

  getAttribute(tool) {
    return axios.get(API_URL + "/v5/products/" + tool, { headers: authHeader() });
  }
  
}

export default new ToolService();