import React, { useRef, useState, useEffect } from 'react'
import NotificationAlert from 'react-notification-alert';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { ValidateFormUser } from 'Validate/ValidateForm'
import {
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    Div,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import moment from 'moment'
import UserService from '../../services/user.service.v3';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';
import './CSS.css'

function UserDetails(props) {

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [isAdd, setIsAdd] = useState(true)

    const data = props.data
    const [id, setId] = useState(data.id || '')
    const [account, setAccount] = useState(data.username || '')
    const [email, setEmail] = useState(data.email || '')
    const [phonenumber, setPhonenumber] = useState(data.phonenumber || '')
    const [fullName, setFullName] = useState(data.fullname || '')
    const [drive, setDrive] = useState(data.drive || '')
    const [quantity, setQuantity] = useState(1)
    const [language, setLanguage] = useState(data.language || '')
    const [password, setPassword] = useState('')
    const [expired, setExpired] = useState(moment(data.expired).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'))
    const [activeCalendar, setActiveCalendar] = useState(false)
    const [loai, setLoai] = useState(data.version || 'Thường')
    const [showloai, setShowload] = useState(false)
    const [calendar, setCalendar] = useState(new Date());
    const PATH = props.path


    const [childID, setChildID] = useState(0)
    const [childIndex, setChildIndex] = useState(0)
    const [childName, setChildName] = useState('')
    const [childHardware, setChildHardware] = useState('')

    function optionsMaMay() {
        var opt = []
        for (var i = 1; i <= 10; i++) {
            opt.push({ value: i, label: i })
        }
        return opt
    }

    const notificationAlertRef = useRef(null);

    const notify = (type, message) => {
        var options = {};
        options = {
            place: 'tc', // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
            message: (
                <div style={{ fontSize: '20px', textAlign: 'center' }}>
                    <div>{message}</div>
                </div>
            ),
            type: type, // success, danger, warning, info, primary
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
    }

    const optionsLanguage = [
        { value: 'Vietnamese', label: 'Vietnamese' },
        { value: 'English', label: 'English' },
    ];

    const optionsLoai = [
        { value: 'Thường', label: 'Thường' },
        PATH == "dhbrender" ? { value: 'Dùng thử', label: 'Dùng thử' } : { value: 'Pro', label: 'Pro' },
    ];

    useEffect(() => {
        if (data.version == "Thường" || data.version == "Pro" || data.version == "Dùng thử") setShowload(true)
        window.scrollTo(0, 0);
    }, [])

    function deleteUser() {
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có chắc chắn muốn xóa tài khoản này ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không'
        }).then((result) => {
            if (result.value) {
                UserService.deleteUserByUserId(PATH, id)
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire({
                                icon: 'success',
                                title: response.data.message,
                                showConfirmButton: false,
                                timer: 1000
                            }).then(res => {
                                props.changePage('', 'delete', id);
                            })
                        } else {
                            swalWithBootstrapButtons.fire(
                                response.data.message,
                                'error'
                            )
                        }
                    }, error => {
                        swalWithBootstrapButtons.fire(
                            'Thất bại !',
                            error,
                            'error'
                        )
                    })
            };
        })
    }

    function userInformationWillUpdate() {
        const date = moment(expired, 'DD-MM-YYYY')
        var v_loai = null
        if (showloai) {
            v_loai = loai
        }
        return {
            username: account,
            password: changePassword(password),
            email: email,
            fullname: fullName,
            language: language,
            drive: drive,
            expired: expired,
            phonenumber: phonenumber,
            loai: v_loai
        }
    }

    function changePassword(pass) {
        return pass.trim() == '' ? '' : pass
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    function updateUser() {
        const validate = ValidateFormUser(userInformationWillUpdate(), expired)
        if (validate) {
            return
        }

        UserService.updateUserByUserId(PATH, id, userInformationWillUpdate())
            .then(result => {
                if (result.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Sửa thành công',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(res => {
                        props.changePage('', 'edit', result.data.user);
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }
            }, error => {
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function selectACalendar(value) {
        setCalendar(value)

        const date = new Date(value)
        const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        setExpired(newDate)
        setActiveCalendar(false)
    }

    function updateChildDevice(data) {
        toggle()

        setIsAdd(false)
        setChildID(data.id)
        setChildIndex(data.childIndex)
        setChildName(data.childName)
        setChildHardware(data.childHardware)
    }

    function deleteChildDevice(data) {
        setChildIndex(data.childIndex)
        setChildName(data.childName)
        setChildHardware(data.childHardware)

        Swal.fire({
            title: 'Xác nhận',
            text: 'Xác nhận xóa mã máy ' + data.childName + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không'
        }).then((result) => {
            if (result.value) {
                UserService.deleteChildDevice(PATH, data.id)
                    .then(response => {
                        if (response.data.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Xóa thành công',
                                showConfirmButton: false,
                                timer: 1000
                            }).then(res => {
                                const newDrive = drive.filter(item => item.id !== data.id);
                                setDrive(newDrive);
                            })
                        } else {
                            notify('danger', response.data.message)
                        }
                    }, error => {
                        swalWithBootstrapButtons.fire(
                            'Thất bại !',
                            error,
                            'error'
                        )
                    })
            };
        })
    }

    function loadFillData() {
        return drive.map((data, key) => {
            return (
                <tr className={data.color} style={{ cursor: "pointer" }} key={key} >
                    <th onClick={() => { updateChildDevice(data) }}>{data.childIndex}</th>
                    <td onClick={() => { updateChildDevice(data) }}>{data.childName}</td>
                    <td onClick={() => { updateChildDevice(data) }} className="table-device-mobile">{data.childHardware}</td>
                    <td><button className="btn btn-danger" type="button" onClick={() => { deleteChildDevice(data) }} >Xóa</button></td>
                </tr>
            )
        })
    };

    return (
        <div className="content">

            <NotificationAlert ref={notificationAlertRef} />

            <Row>
                <Col lg="6" md="6" sm="6">
                    <Card style={{ padding: '15px' }} >
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Tài khoản</Label>
                        </CardHeader>

                        <CardBody>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    value={email}
                                    id="email"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                />

                                <Label style={{ marginTop: "1rem" }} for="account">Tài khoản</Label>
                                <Input
                                    value={account}
                                    id="account"
                                    onChange={(e) => setAccount(e.target.value)}
                                />

                                <Label style={{ marginTop: "1rem" }} for="password">Mật khẩu</Label>
                                <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "-10px" }}>
                                    <Input
                                        style={{ height: "38px", marginTop: "10px" }}
                                        value={password}
                                        id="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-info" type="button" onClick={() => setPassword(Math.random().toString(36).slice(-8))}>Ngẫu nhiên</button>
                                    </div>
                                </div>

                                <Label style={{ marginTop: "1rem" }} for="fullname">Họ tên</Label>
                                <Input
                                    value={fullName}
                                    id="fullname"
                                    onChange={(e) => setFullName(e.target.value)}
                                />

                                <Label style={{ marginTop: "1rem" }} for="fullname">Số điện thoại</Label>
                                <Input
                                    value={phonenumber}
                                    id="phonenumber"
                                    onChange={(e) => setPhonenumber(e.target.value)}
                                />

                                <Label style={{ marginTop: "1rem" }} for="language">Ngôn ngữ</Label>
                                <Select
                                    className="dropdown-select-index-1"
                                    placeholder={language}
                                    value={language}
                                    onChange={(e) => setLanguage(e.value)}
                                    options={optionsLanguage}
                                />

                                {
                                    showloai ?
                                        <>
                                            <Label style={{ marginTop: "1rem" }} for="loai">Loại</Label>
                                            <Select
                                                className="dropdown-select-index-2"
                                                placeholder={loai}
                                                value={loai}
                                                onChange={(e) => setLoai(e.value)}
                                                options={optionsLoai}
                                            />

                                        </>
                                        :
                                        null
                                }

                                {/* <Label style={{ marginTop: "1rem" }} for="drive">Mã máy</Label>
                                <Input
                                    className="dropdown-select-index-3"
                                    value={drive}
                                    id="drive"
                                    onChange={(e) => setDrive(e.target.value)}
                                /> */}

                                <Label style={{ marginTop: "1rem" }} for="expired">Ngày hết hạn</Label>
                                <Input
                                    className="d-block d-sm-none"
                                    type="date"
                                    onChange={(e) => setExpired(e.target.value)}
                                    value={expired}
                                    id="expired"
                                />

                                <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "-10px" }}>
                                    <Input
                                        className="d-none d-sm-block"
                                        style={{ height: "38px", marginTop: "10px" }}
                                        onClick={() => {
                                            if (activeCalendar) {
                                                setActiveCalendar(false)
                                            } else {
                                                setActiveCalendar(true)
                                            }
                                        }}
                                        autocomplete="off"
                                        onChange={(e) => setExpired(e.target.value)}
                                        value={expired}
                                        id="expired"
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setMonth(date.getMonth() + 1)).format('YYYY-MM-DD'))
                                        }}>+1 Tháng</button>

                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setMonth(date.getMonth() + 3)).format('YYYY-MM-DD'))
                                        }}>+3 Tháng</button>

                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setFullYear(date.getFullYear() + 1)).format('YYYY-MM-DD'))
                                        }}>+1 Năm</button>
                                        <button className="btn btn-info" type="button" onClick={() => {
                                            const date = new Date(expired)
                                            setExpired(moment(date.setFullYear(date.getFullYear() + 10)).format('YYYY-MM-DD'))
                                        }}>Vĩnh viễn</button>
                                    </div>
                                </div>
                            </FormGroup>
                        </CardBody>
                        <CardFooter style={{ marginTop: "10px" }}>
                            <div >
                                <Button color="primary" style={{ marginRight: "10px" }} onClick={() => updateUser()}>Sửa tài khoản</Button>
                                <Button color="danger" onClick={() => deleteUser()}>Xóa tài khoản</Button>
                            </div>
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg="6" md="6" sm="6">
                    <Card style={{ padding: '15px' }} >
                        <CardHeader>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>
                                    Mã máy
                                </Label>
                                <div>
                                    <Button color="warning" style={{ marginRight: "10px" }} onClick={() => {
                                        Swal.fire({
                                            title: 'Xác nhận ?',
                                            text: 'Xác nhận đặt lại tất cả mã máy ?',
                                            icon: 'warning',
                                            showDenyButton: true,
                                            confirmButtonText: 'Xác nhận',
                                            denyButtonText: `Hủy`,
                                        }).then((result) => {
                                            /* Read more about isConfirmed, isDenied below */
                                            if (result.isConfirmed) {
                                                const obj = {
                                                    username: account,
                                                }

                                                UserService.resetChildDevice(PATH, obj).then(response => {
                                                    if (response.data.success) {
                                                        Swal.fire({
                                                            icon: 'success',
                                                            title: response.data.message,
                                                            showConfirmButton: false,
                                                            timer: 1000
                                                        }).then(res => {
                                                            setDrive(response.data.device);
                                                        })
                                                    } else {
                                                        swalWithBootstrapButtons.fire(
                                                            'Thất bại',
                                                            response.data.message
                                                        )
                                                    }
                                                }, error => {
                                                    swalWithBootstrapButtons.fire(
                                                        'Thất bại',
                                                        error,
                                                        'error'
                                                    )
                                                })
                                            }
                                        })
                                    }}>
                                        Đặt lại tất cả mã máy
                                    </Button>

                                    <Button color="primary" onClick={() => {
                                        toggle()

                                        var maxIndex = 0
                                        drive.forEach(item => {
                                            if (item.childIndex > maxIndex) {
                                                maxIndex = item.childIndex
                                            }
                                        });

                                        setIsAdd(true)
                                        setChildIndex(maxIndex + 1)
                                        setChildName("Device " + (maxIndex + 1))
                                        setChildHardware("*")
                                    }}>
                                        Thêm mã máy
                                    </Button>
                                </div>

                            </div>
                        </CardHeader>
                        <CardBody>
                            <Table className="table-hover" style={{ lineHeight: "15px" }}>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Index</th>
                                        <th>Tên thiết bị</th>
                                        <th className="table-device-mobile">Mã máy</th>
                                        <th>Xóa</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadFillData()}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Popup thêm childDevice*/}
            <div>
                <Modal isOpen={modal} toggle={toggle} style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90%'
                }}>
                    <ModalHeader toggle={toggle}>Thêm mã máy</ModalHeader>
                    <ModalBody style={{ marginBottom: "20px" }}>
                        <FormGroup>
                            {
                                isAdd
                                    ?
                                    <>
                                        <Label style={{ marginTop: "1rem" }} for="quantity">Số lượng máy</Label>
                                        <Select
                                            className="dropdown-select-index-4"
                                            isSearchable={false}
                                            placeholder={quantity}
                                            value={quantity}
                                            onChange={(e) => {
                                                setQuantity(e.value)
                                                console.log(e)
                                            }}
                                            options={optionsMaMay()}
                                        />
                                    </>
                                    :
                                    <>
                                        <Label for="childIndex">Index</Label>
                                        <Input
                                            value={childIndex}
                                            id="childIndex"
                                            onChange={(e) => {
                                                setChildIndex(e.target.value)
                                            }}
                                            readOnly={!isAdd}
                                        />

                                        <Label style={{ marginTop: "1rem" }} for="childName">Tên thiết bị</Label>
                                        <Input
                                            value={childName}
                                            id="childName"
                                            onChange={(e) => {
                                                setChildName(e.target.value)
                                            }}
                                        />

                                        <Label style={{ marginTop: "1rem" }} for="childHardware">Mã máy</Label>
                                        <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "-10px" }}>
                                            <Input
                                                style={{ height: "38px", marginTop: "10px" }}
                                                value={childHardware}
                                                id="childHardware"
                                                onChange={(e) => setChildHardware(e.target.value)}
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-info" type="button" onClick={() => setChildHardware("*")}>Đặt lại</button>
                                            </div>
                                        </div>
                                    </>
                            }
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color={isAdd ? "primary" : "warning"} style={{ marginRight: "10px" }} onClick={() => {
                            toggle()

                            if (isAdd) {
                                const obj = {
                                    quantity: quantity,
                                    username: account,
                                }

                                UserService.insertChildDevice(PATH, obj).then(response => {
                                    if (response.data.success) {
                                        Swal.fire({
                                            icon: 'success',
                                            title: response.data.message,
                                            showConfirmButton: false,
                                            timer: 1000
                                        }).then(res => {
                                            setDrive(response.data.device);
                                        })
                                    } else {
                                        swalWithBootstrapButtons.fire(
                                            'Thất bại',
                                            response.data.message
                                        )
                                    }
                                }, error => {
                                    swalWithBootstrapButtons.fire(
                                        'Thất bại',
                                        error,
                                        'error'
                                    )
                                })
                            } else {
                                const obj = {
                                    username: account,
                                    childIndex: childIndex,
                                    childName: childName,
                                    childHardware: childHardware,
                                }

                                UserService.updateChildDevice(PATH, obj, childID).then(response => {
                                    if (response.data.success) {
                                        Swal.fire({
                                            icon: 'success',
                                            title: response.data.message,
                                            showConfirmButton: false,
                                            timer: 1000
                                        }).then(res => {
                                            setDrive(prevDrive =>
                                                prevDrive.map(item =>
                                                    item.id === response.data.device.id
                                                        ? response.data.device
                                                        : item
                                                )
                                            );
                                        })
                                    } else {
                                        swalWithBootstrapButtons.fire(
                                            response.data.message,
                                            'error'
                                        )
                                    }
                                }, error => {
                                    swalWithBootstrapButtons.fire(
                                        'Thất bại !',
                                        error,
                                        'error'
                                    )
                                })
                            }
                        }}>
                            {isAdd ? "Thêm mã máy" : "Sửa mã máy"}
                        </Button>

                        <Button color="danger" onClick={toggle}>
                            Hủy
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )
}

export default UserDetails