import React, { useState, useEffect } from 'react'
import {
    Table,
    Input,
    Button,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu,
    Col
} from "reactstrap";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

import LangDetails from "../components/Childrens/LanguageDetails"
import languageService from 'services/language.service';


function Orders() {
    const [page, setPage] = useState('')
    const [dataProps, setDataProps] = useState(null)
    const [search, setSearch] = useState(null)

    const [tool, setTool] = useState('dhbreupyoutube')
    const [toolName, setToolName] = useState('DHB Reup Youtube')
    const [language, setLanguage] = useState([])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        loadLanguage("dhbreupyoutube")
    }, [])

    function loadLanguage(tool) {
        setTool(tool)

        languageService.getLanguage(tool).then((result) => {
            setLanguage(result.data)
        })
    }

    function commitLanguage() {
        languageService.commitLanguage(tool).then((result) => {
            if (result.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Commit thành công',
                    showConfirmButton: true,
                    timer: 1000
                }).then(res => {

                    //window.location.reload()
                })
            } else {
                swalWithBootstrapButtons.fire(
                    'Thất bại !',
                    result.data.message,
                    'error'
                )
            }
        })
    }

    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }

    function table() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th className="table-device-mobile">Key</th>
                        <th className="table-device-mobile">Vietnamese</th>
                        <th className="table-device-mobile" >English</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }

    function toAddNew() {
        var val = {}
        val.tool = tool
        setDataProps(val)
        setPage('detail')
    }

    function toDetailView(val) {
        setPage('detail')
        val.tool = tool
        val.page = page
        setDataProps(val)
    }

    function loadFillData() {
        return language.filter((data) => {
            try {
                if (data.key.toLowerCase().includes(search.toLowerCase()) || data.vi.toLowerCase().includes(search.toLowerCase()) || data.en.toLowerCase().includes(search.toLowerCase())) {
                    return data
                }
            } catch (error) {
                return data
            }
        }).map((data, key) => {
            return (
                <tr onClick={() => toDetailView(data)} style={{ cursor: "pointer" }} key={key}>
                    <td className="table-device-mobile">{data.key}</td>
                    <td className="table-device-mobile">{data.vi}</td>
                    <td className="table-device-mobile">{data.en}</td>
                </tr>
            )
        })
    };

    function back() {
        return (
            <Button
                color="info"
                onClick={() => { setPage(''); loadLanguage(tool) }}>
                &laquo; Quay lại
            </Button>
        )
    }

    return (
        <div className="content">
            {
                page == "detail"
                    ?
                    <>
                        {back()}
                        <LangDetails data={dataProps} />
                    </>
                    :
                    <div className="card">

                        <div className="card-header d-flex">

                            <div className="p-2">
                                <Dropdown isOpen={dropdownOpen} toggle={toggle} >
                                    <DropdownToggle className="btn-secondary" caret>{toolName}</DropdownToggle>
                                    <DropdownMenu >
                                        <DropdownItem onClick={() => { loadLanguage('dhbreupyoutube'); setToolName("DHB Reup Youtube") }} >Reup Youtube</DropdownItem>
                                        <DropdownItem onClick={() => { loadLanguage('dhbreupfacebook'); setToolName("DHB Reup Facebook") }} >Reup Facebook</DropdownItem>
                                        <DropdownItem onClick={() => { loadLanguage('dhbreuptiktok'); setToolName("DHB Reup TikTok") }} >Reup TikTok</DropdownItem>
                                        <DropdownItem onClick={() => { loadLanguage('dhbreupinstagram'); setToolName("DHB Reup Instagram") }} >Reup Instagram</DropdownItem>
                                        <DropdownItem onClick={() => { loadLanguage('dhbstreampro'); setToolName("DHB Stream Pro") }} >Stream Pro</DropdownItem>
                                        <DropdownItem onClick={() => { loadLanguage('dhbgologinmanager'); setToolName("DHB GoLogin Manager") }} >GoLogin Manager</DropdownItem>
                                        <DropdownItem onClick={() => { loadLanguage('dhbstreamfacebook'); setToolName("DHB Stream Facebook") }} >Stream Facebook</DropdownItem>
                                        <DropdownItem onClick={() => { loadLanguage('dhbyoutubemanager'); setToolName("DHB Youtube Manager") }} >Youtube Manager</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>

                            <div className="p-2">
                                <Button className="btn-primary" onClick={toAddNew}>
                                    Thêm mới
                                </Button>
                            </div>

                            <div className="p-2">
                                <Button className="btn-success" onClick={commitLanguage}>
                                    Commit
                                </Button>
                            </div>

                            <div className='ml-auto p-2'>
                                <Input
                                    style={{ height: "38px", width: "100%", marginTop: "7px" }}
                                    value={search}
                                    onChange={(e) => searchSpace(e)}
                                    placeholder="Tìm kiếm"
                                />
                            </div>

                        </div>

                        <div className="card-body">
                            {table()}
                        </div>
                    </div>
            }
        </div>
    )
}

export default Orders