import { error } from "jquery";
import React, { useState } from "react"
import {
    FormGroup,
    Label,
    Input
} from "reactstrap";
import AuthService from "../services/auth.service";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

function Login() {
    const [account, setAccount] = useState('')
    const [password, setPassword] = useState('')
    const [showload, setShowload] = useState(false)


    function Login() {
        setShowload(true)
        AuthService.login(account, password)
            .then(response => {
                setShowload(false)
                if (response.success) {
                    if (response.role == "ADMIN") {
                        window.location = "/admin/dashboard"
                    } else {
                        window.location = "/admin/lichsu"
                    }
                    
                } else {
                    Swal.fire({
                        title: 'Thông báo',
                        text: response.message,
                        icon: 'error',
                    })
                }
            }, error => {
                setShowload(false)
                Swal.fire({
                    title: 'Thông báo',
                    text: error,
                    icon: 'error',
                })
            })
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                margin: "auto",
                justifyContent: "center",
                alignItems: "center",
                display: "flex"
            }}>



            <div style={{ width: "35vh", height: "50vh", padding: "1rem" }}>

                <FormGroup>
                    <Label style={{ marginTop: "0.5rem" }} for="account">Tài khoản</Label>
                    <Input
                        value={account}
                        id="account"
                        onChange={(e) => setAccount(e.target.value)}
                    />
                    <Label style={{ marginTop: "1rem" }} for="password">Mật khẩu</Label>

                    <Input
                        type="password"
                        value={password}
                        id="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormGroup>

                <div style={{ float: "right", marginTop: "0.5rem" }}>
                    <div style={{ width: "100%" }}></div>
                    <button className="btn btn-primary" onClick={() => Login()}>Đăng nhập {showload ? <span class="spinner-border spinner-border-sm"></span> : null}</button>
                </div>
            </div>
        </div>
    )
}

export default Login