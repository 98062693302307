import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Alert(message) {
    Swal.fire({
        title: 'Thông báo',
        text: message,
        icon: 'warning'
    })
}

function ValidateFormMember(obj) {
    if(obj.username == '' || obj.email == '' || obj.fullname == '' || obj.password == ''){
        Alert('Bạn chưa nhập đủ thông tin')
        return true
    }
    if(obj.password.length < 6){
        Alert('Mật khẩu phải lớn hơn hoặc bằng 6 ký tự')
        return true
    }
    if(REGEX_EMAIL.test(obj.email.toLowerCase()) == false) {
        Alert('Email không đúng định dạng')
        return true
    }
}

function ValidateFormUser(obj, date) {
    if(obj.username == '' || obj.email == '' || obj.fullname == '' || obj.drive == '' || date == ''){
        Alert('Bạn chưa nhập đủ thông tin')
        return true
    }
    
    if(REGEX_EMAIL.test(obj.email.toLowerCase()) == false) {
        Alert('Email không đúng định dạng')
        return true
    }
}

export {
    ValidateFormMember,
    ValidateFormUser
} 