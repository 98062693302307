import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/admin/v3/";

class UserService {

  getReport() {
    return axios.get(API_URL + "report", { headers: authHeader() });
  }

  searchUser(tool, keyword) {
    console.log(tool)
    return axios.get(API_URL + tool + "?search=" + keyword, { headers: authHeader() });
  }

  getAllUser(tool, indexItem, searchKeyword) {
    if (searchKeyword) {
      return axios.get(API_URL + tool + "?search=" + searchKeyword, { headers: authHeader() });
    } else {
      return axios.get(API_URL + tool + "?indexItem=" + indexItem, { headers: authHeader() });
    }
  }

  deleteUserByUserId(tool, id) {
    return axios.delete(API_URL + tool + "/" + id, { headers: authHeader() });
  }

  updateUserByUserId(tool, id, obj) {
    return axios.put(API_URL + tool + "/" + id, obj, { headers: authHeader() })
  }

  createUser(tool, obj) {
    return axios.post(API_URL + tool, obj, { headers: authHeader() })
  }


  insertChildDevice(tool, obj) {
    return axios.post(API_URL + tool + "/device", obj, { headers: authHeader() })
  }

  updateChildDevice(tool, obj, id) {
    return axios.put(API_URL + tool + "/device/" + id, obj, { headers: authHeader() })
  }

  resetChildDevice(tool, obj) {
    return axios.put(API_URL + tool + "/device/reset", obj, { headers: authHeader() })
  }

  deleteChildDevice(tool, id) {
    return axios.delete(API_URL + tool + "/device/" + id, { headers: authHeader() });
  }

  createUserMail(tool, obj) {
    return axios.post(API_URL + `now/${tool}`, obj, { headers: authHeader() })
  }

}

export default new UserService();
