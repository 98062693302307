import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle
} from "reactstrap";

import OrderDetail from "../components/Childrens/OrderDetails.v3"
import OrderService from "../services/order.service"
import Select from 'react-select';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

function Orders() {
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState('')
    const [dataProps, setDataProps] = useState([])
    const [search, setSearch] = useState(null)
    const [pageOrder, setPageOrder] = useState(1)

    const [showLoadTruoc, setShowLoadTruoc] = useState(false)
    const [showLoadSau, setShowLoadSau] = useState(false)

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        OrderService.getOrder(pageOrder).then((result) => {
            setOrders(result.data)
        })
    }, [])

    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }

    function table() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th >ID Đơn</th>
                        <th >Họ tên</th>

                        <th className="table-device-mobile">Email</th>
                        <th className="table-device-mobile">Số điện thoại</th>

                        <th className="table-device-mobile" >Sản phẩm</th>

                        <th className="table-device-mobile">Người bán</th>
                        <th>Phương thức</th>
                        <th>Thời gian</th>

                        <th className="text-right">Tổng tiền</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }

    function toDetailView(val) {
        setPage('detail')
        setDataProps(val)
    }

    function loadFillData() {
        return orders.filter((data) => {
            if (search == null) {

                if (data.status == "Hoàn thành") data.color = "table-success"
                if (data.status == "Tạm giữ" || data.status == "Chờ thanh toán") data.color = "table-warning"
                if (data.status == "Đã hủy") data.color = "table-danger"

                return data
            } else if (data.email.toLowerCase().includes(search.toLowerCase()) || data.phone.toLowerCase().includes(search.toLowerCase()) || data.fullname.toLowerCase().includes(search.toLowerCase())) {
                return data
            }
        }).map((data, key) => {
            return (
                <tr className={data.color} onClick={() => toDetailView(data)} style={{ cursor: "pointer" }} key={key}>
                    <th >{data.id}</th>
                    <td >{data.fullname}</td>

                    <td className="table-device-mobile">{data.email}</td>
                    <td className="table-device-mobile">{data.phone}</td>

                    <td className="table-device-mobile">{data.products[0].name}</td>

                    <td className="table-device-mobile">{data.seller}</td>
                    <td>{data.payment_method}</td>
                    <td>{moment(data.createdAt).format('DD/MM h:mm')}</td>

                    <td className="text-right">{data.total}</td>
                </tr>

            )
        })
    };

    function back() {
        return (
            <Button
                color="info"
                onClick={() => setPage('')}>
                &laquo; Quay lại
            </Button>
        )
    }

    return (
        <div className="content">
            {
                page == "detail"
                    ?
                    <>
                        {back()}
                        <OrderDetail data={dataProps} />
                    </>
                    :
                    <div className="card">

                        <div className="card-header">
                            <div className="function justify-content-between">

                                <CardTitle tag="h5">Đơn hàng</CardTitle>
                                <Input
                                    style={{ height: "38px", marginTop: "7px" }}
                                    className="col col-lg-3 float-right"
                                    value={search}
                                    onChange={(e) => searchSpace(e)}
                                    placeholder="Tìm kiếm"
                                />

                            </div>
                        </div>

                        <div className="card-body">
                            {table()}
                        </div>

                        <div className="card-footer">
                            <hr />
                            <nav className="pagination justify-content-center ">
                                <ul className="pagination">
                                    <Button
                                        style={{ marginRight: "10px" }}
                                        color="info"
                                        onClick={() => {
                                            var page = pageOrder - 1
                                            if (page < 1) {
                                                Swal.fire({
                                                    icon: 'error',
                                                    title: 'Đây là trang đầu',
                                                    showConfirmButton: false,
                                                    timer: 1000
                                                })
                                                return
                                            }
                                            setShowLoadTruoc(true)
                                            setPageOrder(page)
                                            OrderService.getOrder(page).then((result) => {
                                                setOrders(result.data)
                                                setShowLoadTruoc(false)
                                            })
                                        }}>
                                        {showLoadTruoc ? <span class="spinner-border spinner-border-sm"></span> : <> &laquo; Trang trước </>}

                                    </Button>
                                    <Button

                                        color="info"
                                        onClick={() => {
                                            setShowLoadSau(true)
                                            var page = pageOrder + 1
                                            setPageOrder(page)
                                            OrderService.getOrder(page).then((result) => {
                                                setOrders(result.data)
                                                setShowLoadSau(false)
                                            })
                                        }}>
                                        {showLoadSau ? <span class="spinner-border spinner-border-sm"></span> : <> Trang sau &raquo; </>}
                                    </Button>
                                </ul>
                            </nav>
                        </div>

                    </div>
            }
        </div>
    )
}

export default Orders