import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    CardTitle,
    Input,
    Button
} from "reactstrap";

import HistoryService from "../services/history.service"
import moment from 'moment'

function Orders() {
    const [histories, setHistories] = useState([])
    const [dataProps, setDataProps] = useState([])
    const [search, setSearch] = useState(null)


    useEffect(() => {
        HistoryService.getHistory().then((result) => {
            setHistories(result.data)
        })
    }, [])

    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }

    function table() {
        return (
            <Table className="table-hover reponsive" style={{ lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th className="table-device-mobile">Người thực hiện</th>
                        <th >Phần mềm</th>
                        <th className="table-device-mobile" >Hành động</th>
                        <th  >Thông báo</th>
                        <th className="table-device-mobile">Thời gian</th>
                    </tr>
                </thead>
                <tbody>
                    {loadFillData()}
                </tbody>
            </Table>
        )
    }

    function loadFillData() {
        return histories.filter((data) => {
            if (search == null) {

                if (data.action == "Thêm") data.color = "table-success"
                if (data.action == "Gia hạn") data.color = "table-info"
                if (data.action == "Sửa") data.color = "table-warning"
                if (data.action == "Xóa") data.color = "table-danger"

                return data
            } else if (data.email.toLowerCase().includes(search.toLowerCase()) || data.phone.toLowerCase().includes(search.toLowerCase()) || data.fullname.toLowerCase().includes(search.toLowerCase())) {
                return data
            }
        }).map((data, key) => {
            return (
                <tr className={data.color} style={{ cursor: "pointer" }} key={key}>
                    <th className="table-device-mobile">{data.user}</th>
                    <td >{data.tool}</td>
                    <td className="table-device-mobile">{data.action}</td>
                    <td >{data.message}</td>
                    <td className="table-device-mobile">{moment(data.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            <div className="card">
                <div className="card-header">
                    <div className="function justify-content-between">

                        <CardTitle tag="h5">Lịch sử thao tác</CardTitle>
                        <Input
                            style={{ height: "38px", marginTop : "7px" }}
                            className="col col-lg-3 float-right"
                            value={search}
                            onChange={(e) => searchSpace(e)}
                            placeholder="Tìm kiếm"
                        />
                    </div>
                </div>

                <div className="card-body">
                    {table()}
                </div>
            </div>

        </div>
    )
}

export default Orders