/*!

=========================================================
* Paper Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";

import UserService from '../services/user.service';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      chart: dashboardNASDAQChart,
      total_sales: "0 tr",
      average_sales: "0 tr",
      total_orders: 0,
      total_sales_today: "0 tr"
    }
  }

  componentDidMount() {
    UserService.getReport()
      .then(response => {
        const data = response.data;
        const datachart = {
          data: {
            labels: data.chart.key,
            datasets: [
              {
                data: data.chart.value,
                fill: false,
                borderColor: "#51CACF",
                backgroundColor: "transparent",
                pointBorderColor: "#51CACF",
                pointRadius: 4,
                pointHoverRadius: 4,
                pointBorderWidth: 8,
              },
            ],
          },
          options: {
            legend: {
              display: false,
              position: "top",
            },
          },
        };

        this.setState({ chart: datachart, total_sales: data.total_sales, average_sales: data.average_sales, total_orders: data.total_orders, total_sales_today: data.total_sales_today });
     
      }, error => {
        alert("Lỗi, đổ dữ liệu : " + error)
      }

      ).catch(error => {
        alert("Catch đổ dữ liệu : " + error)
      })
  }


  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-money-coins text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Doanh thu</p>
                        <CardTitle tag="p">{this.state.total_sales_today}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-calendar" />Hôm nay
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-compass-05 text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Trung bình</p>
                        <CardTitle tag="p">{this.state.average_sales}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Tháng này
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Tổng đơn hàng</p>
                        <CardTitle tag="p">{this.state.total_orders}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fas fa-sync-alt" /> Tháng này
                  </div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-bank text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Doanh thu</p>
                        <CardTitle tag="p">{this.state.total_sales}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="far fa-calendar" /> Tháng này
                  </div>
                </CardFooter>
              </Card>
            </Col>

          </Row>

          {/* <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Biểu đồ doanh thu</CardTitle>
                  <p className="card-category">Tháng này</p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row> */}
          <Row>
            <Col md="12">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Doanh thu tháng này</CardTitle>
                  {/* <p className="card-category">Tháng này</p> */}
                </CardHeader>
                <CardBody>
                  <Line
                    data={this.state.chart.data}
                    options={this.state.chart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  {/* <div className="chart-legend">
                    <i className="fa fa-circle text-info" /> Tesla Model S{" "}
                    <i className="fa fa-circle text-warning" /> BMW 5 Series
                  </div> */}
                  <hr />
                  <div className="card-stats">
                    <i className="fa fa-check" /> Biểu đồ doanh thu tháng này - Nguồn dữ liệu Wordpress
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
