import React, { useState, useEffect } from 'react'
import NotificationAlert from 'react-notification-alert';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { ValidateFormUser } from 'Validate/ValidateForm'
import {
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    Div,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";
import moment from 'moment'

import UserService from '../../services/user.service.v3';

import UserDetails from "./UserDetails.v3"
import UserDetailsOld from "./DHBUserDetails"

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';

import './CSS.css'

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function OrderDetails(props) {
    const data = props.data
    const [id, setId] = useState(data.id || '')
    const [account, setAccount] = useState(data.email.includes("@") ? data.email.split("@")[0] : data.email)
    const [email, setEmail] = useState(data.email || '')
    const [facebook, setFacebook] = useState(data.facebook || '')
    const [phonenumber, setPhonenumber] = useState(data.phonenumber || '')
    const [fullName, setFullName] = useState(data.fullname || '')
    const [drive, setDrive] = useState(1)
    const [language, setLanguage] = useState('Vietnamese')
    const [password, setPassword] = useState(Math.random().toString(36).slice(-8))
    const [expired, setExpired] = useState(moment(new Date().addDays(data.products[0].expired)).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'))

    var optionsTool = () => {
        var prd = []

        data.products.forEach(item => {
            prd.push({
                value: item.value,
                label: item.name,
                expired: item.expired,
                loai: item.loai,
                attributes: item.attributes
            })
        })
        return prd
    }

    const [product, setProduct] = useState(optionsTool()[0])
    //const [loai, setLoai] = useState(data.products[0].loai || 'Thường')

    const [activeCalendar, setActiveCalendar] = useState(false)
    const [calendar, setCalendar] = useState(new Date());

    const [message, setMessage] = useState('')
    const [modalShow, setModalShow] = useState(false);
    const [showload, setShowload] = useState(false)

    const [showCopy, setShowCopy] = useState(false)
    const [showCopyEmail, setShowCopyEmail] = useState(false)

    const optionsLanguage = [
        { value: 'Vietnamese', label: 'Vietnamese' },
        { value: 'English', label: 'English' },
    ];

    const [optionsThoiHan, setOptionsThoiHan] = useState([])
    const [thoiHan, setThoiHan] = useState(null)

    const [optionLoai, setOptionLoai] = useState([])
    const [loai, setLoai] = useState(null)

    const [selectedRow, setSelectedRow] = useState(null);
    const [containUsers, setContainUsers] = useState([]);

    const [isCreate, setIsCreate] = useState(true)
    const [lstProduct, setLstProduct] = useState([])

    useEffect(() => {
        data.products[0].attributes.forEach(item => {
            if (item.name.toLowerCase() == "thời hạn") {
                setOptionsThoiHan([item])
                setThoiHan(item)
                if (item.value.includes("10 máy")) {
                    setDrive(10)
                } else {
                    setDrive(1)
                }
            } else if (item.name.toLowerCase() == "loại") {
                setOptionLoai([item])
                setLoai(item)
            }
        })

        var lstProduct = []
        data.products.forEach(dataProduct => {
            for (let index = 0; index < dataProduct.quantity; index++) {
                lstProduct.push(dataProduct)
            }
        });
        setLstProduct(lstProduct);


        setSelectedRow(0);
        selectContainAccount(data.products[0].value)
    }, [])

    function selectContainAccount(tool) {
        UserService.getAllUser(tool, 0, email).then(response => {
            setContainUsers(response.data.user)
        })
    }

    function optionsMaMay() {
        var opt = []
        for (var i = 1; i < 16; i++) {
            opt.push({ value: i, label: i })
        }
        return opt
    }

    function userInformationWillUpdate() {
        const date = moment(expired, 'YYYY-MM-DD')

        var _loai = ''
        try {
            _loai = loai.value
        } catch (error) {
        }

        return {
            "username": account,
            "password": password,
            "email": email,
            "fullname": fullName,
            "language": language,
            "drive": drive,
            "expired": expired,
            "loai": _loai,
            "thoihan": thoiHan.value,
            "iddon": id
        }
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    function createUser() {
        const validate = ValidateFormUser(userInformationWillUpdate(), expired)
        if (validate) {
            return
        }

        setShowload(true)

        UserService.createUser(product.value.toLowerCase(), userInformationWillUpdate())
            .then((result) => {
                setShowload(false)

                if (result.data.success) {
                    setMessage(`Username : ${account} \nPassword : ${password} \n \nPass unrar : ${result.data.passunrar}  \n \nMọi chi tiết liên hệ : https://facebook.com/dhbtools \nWebsite : https://dhbtools.com`)
                    selectContainAccount(product.value)


                    Swal.fire({
                        icon: 'success',
                        title: 'Tạo tài khoản thành công',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(res => {
                        setModalShow(true)
                        //window.location.reload()
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }

            }, error => {
                setShowload(false)
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function createUserMail() {
        const validate = ValidateFormUser(userInformationWillUpdate(), expired)
        if (validate) {
            return
        }
        setShowload(true)

        UserService.createUserMail(product.value.toLowerCase(), userInformationWillUpdate())
            .then((result) => {
                setShowload(false)

                if (result.data.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Tạo tài khoản và gửi email thành công',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(res => {
                        window.location.reload()
                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }

            }, error => {
                setShowload(false)
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function updateUser() {
        var tool = lstProduct[selectedRow].value

        UserService.updateUserByUserId(tool, id, {
            username: account,
            email: email,
            fullname: fullName,
            language: language,
            expired: expired,
            version: loai,
            phonenumber: phonenumber,
            password: ""
        })
            .then(result => {
                if (result.data.success) {
                    selectContainAccount(tool)

                    Swal.fire({
                        icon: 'success',
                        title: 'Sửa thành công',
                        showConfirmButton: false,
                        timer: 1000
                    }).then(res => {

                    })
                } else {
                    swalWithBootstrapButtons.fire(
                        'Thất bại !',
                        result.data.message,
                        'error'
                    )
                }
            }, error => {
                swalWithBootstrapButtons.fire(
                    'Lỗi không xác định !',
                    error.message,
                    'error'
                )
            })
    }

    function selectACalendar(value) {
        setCalendar(value)

        const date = new Date(value)
        const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        setExpired(newDate)
        setActiveCalendar(false)
    }

    const style = {
        maxHeight: '230px',
        minHeight: '230px',
        resize: 'none',
        padding: '9px',
        boxSizing: 'border-box',
        fontSize: '15px',
        marginTop: '25px'
    };

    function copyToClipboard() {
        setShowCopy(true)
        var textField = document.createElement('textarea')
        textField.value = message
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    function copyEmailToClipboard() {
        setShowCopyEmail(true)
        var textField = document.createElement('textarea')
        textField.value = email
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }

    function back() {
        return (
            <Button
                color="info"
                onClick={() => this.setState({ page: "" })}>
                &laquo; Quay lại
            </Button>

        )
    }

    function MyVerticallyCenteredModal(props) {

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Thành công
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "0px" }}>
                        <Input
                            style={{ height: "38px", marginTop: "10px" }}
                            value={email}
                        />
                        <div className="input-group-append">
                            <button className="btn btn-info" type="button" onClick={copyEmailToClipboard}>{
                                showCopyEmail
                                    ?
                                    <>
                                        Đã sao chép
                                    </>
                                    :
                                    <>
                                        Sao chép
                                    </>
                            }</button>
                        </div>
                    </div>

                    {/* <input type="email" class="form-control" value={email} style={{ marginBottom: "15px" }} /> */}
                    <textarea rows="50" style={style} className="form-control" value={message} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={copyToClipboard} className="btn btn-info" style={{ marginRight: "10px" }} >
                        {
                            showCopy
                                ?
                                <>
                                    Đã sao chép
                                </>
                                :
                                <>
                                    Sao chép
                                </>
                        }
                    </Button>
                    {/* <Button onClick={props.onHide} className="btn btn-danger" >Đóng</Button> */}
                    <Button
                        onClick={() => {
                            window.location.reload()
                        }}
                        className="btn btn-danger">Đóng</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className="content">
            <Row>
                <Col lg="5" md="5" sm="5">
                    <Card style={{ padding: '15px' }} >
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Đơn hàng</Label>
                        </CardHeader>

                        <CardBody>
                            <Table className="table-hover" style={{ lineHeight: "15px" }}>
                                <thead className="text-primary">
                                    <tr>
                                        <th >Tên sản phẩm</th>
                                        <th >Thời hạn</th>
                                        <th >Loại</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        lstProduct.map((dataProduct, key) => {
                                            // Trả về mảng phần tử JSX cho mỗi sản phẩm
                                            return (
                                                <>
                                                    <tr
                                                        className={selectedRow === key ? 'table-success' : ''}
                                                        style={{ cursor: "pointer", fontWeight: "normal" }}
                                                        key={key}
                                                        onClick={() => {
                                                            setSelectedRow(key);
                                                            selectContainAccount(dataProduct.value)

                                                            setProduct(dataProduct)
                                                            setOptionLoai([])
                                                            setOptionsThoiHan([])
                                                            setLoai(null)
                                                            setThoiHan(null)
                                                            dataProduct.attributes.forEach(item => {
                                                                if (item.name.toLowerCase() == "thời hạn") {
                                                                    setOptionsThoiHan([item])
                                                                    setThoiHan(item)
                                                                    if (item.value.includes("10 máy")) {
                                                                        setDrive(10)
                                                                    } else {
                                                                        setDrive(1)
                                                                    }
                                                                } else if (item.name.toLowerCase() == "loại") {
                                                                    setOptionLoai([item])
                                                                    setLoai(item)
                                                                }
                                                            })

                                                            //setLoai(e.loai)
                                                            setExpired(moment(new Date().addDays(dataProduct.expired)).format('YYYY-MM-DD'))

                                                            setIsCreate(true)
                                                        }}
                                                    >

                                                        <th>{dataProduct.name}</th>
                                                        {
                                                            dataProduct.attributes.length == 1 ?
                                                                <>
                                                                    <th>{dataProduct.attributes[0].value}</th>
                                                                    <th></th>
                                                                </>
                                                                :
                                                                <>
                                                                    <th>{dataProduct.attributes[0].value}</th>
                                                                    <th>{dataProduct.attributes[1].value}</th>
                                                                </>
                                                        }
                                                    </tr>
                                                </>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>

                    <Card style={{ padding: '15px' }} >
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Tài khoản đã có</Label>
                        </CardHeader>

                        <CardBody>
                            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                <thead className="text-primary">
                                    <tr>
                                        <th>Tài khoản</th>
                                        <th>Máy</th>
                                        <th>Hết hạn</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        containUsers.map((dataAccount, key) => (
                                            <tr
                                                onClick={() => {
                                                    setIsCreate(false);
                                                    setFullName(dataAccount.fullname)
                                                    setEmail(dataAccount.email)
                                                    setAccount(dataAccount.username)
                                                    setPassword("");
                                                    setId(dataAccount.id);
                                                    setLanguage(dataAccount.language);
                                                    setLoai(dataAccount.version)

                                                    var dateExpired = moment(dataAccount.expired)
                                                    if (moment(dataAccount.expired) < new Date()) {
                                                        dateExpired = new Date()
                                                    }

                                                    setExpired(dateExpired.add(product.expired, 'days').format('YYYY-MM-DD'))
                                                }}
                                                style={{ cursor: "pointer", fontWeight: "normal" }}
                                                key={key}
                                            >
                                                <td>{dataAccount.username}</td>
                                                <td>{dataAccount.versionapi == 3 ? dataAccount.drive.length : dataAccount.drive.split(",").length}</td>
                                                <td>{moment(dataAccount.expired).format('DD/MM/yyyy')}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>

                {isCreate
                    ?
                    <Col lg="7" md="7" sm="7">
                        <Card style={{ padding: '15px' }} >
                            <CardHeader>
                                <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Tạo mới tài khoản</Label>
                            </CardHeader>

                            <CardBody>
                                <FormGroup>
                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <Label for="fullname">Họ tên</Label>
                                            <Input
                                                value={fullName}
                                                id="fullname"
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-5">
                                            <Label for="account">Facebook URL</Label>
                                            <Input
                                                value={facebook}
                                                id="facebook"
                                                onChange={(e) => setFacebook(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <Label for="account">Số điện thoại</Label>
                                            <Input
                                                value={phonenumber}
                                                id="phonenumber"
                                                onChange={(e) => setPhonenumber(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-row" style={{ marginTop: "10px" }}>
                                        <div className="form-group col-md-4">
                                            <Label for="email">Email</Label>
                                            <Input
                                                value={email}
                                                id="email"
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                    var user = e.target.value
                                                    if (user.includes("@")) {
                                                        var arr = user.split('@')
                                                        setAccount(arr[0])
                                                    } else {
                                                        setAccount(user)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <Label for="account">Tài khoản</Label>
                                            <Input
                                                value={account}
                                                id="account"
                                                onChange={(e) => setAccount(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group col-md-4">
                                            <Label for="password">Mật khẩu</Label>
                                            <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "0px" }}>

                                                <Input
                                                    style={{ height: "38px" }}
                                                    value={password}
                                                    id="password"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                    <button style={{ marginTop: "0px" }} className="btn btn-info" type="button" onClick={() => setPassword(Math.random().toString(36).slice(-8))}>Ngẫu nhiên</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-4">
                                            <Label for="tool">Sản phẩm</Label>

                                            <Select
                                                isDisabled={true}
                                                className="dropdown-select-index-1"
                                                isSearchable={false}
                                                placeholder={product}
                                                value={product}
                                                onChange={(e) => {
                                                    console.log(e)
                                                    setProduct(e)
                                                    setOptionLoai([])
                                                    setOptionsThoiHan([])
                                                    setLoai(null)
                                                    setThoiHan(null)
                                                    e.attributes.forEach(item => {
                                                        if (item.name.toLowerCase() == "thời hạn") {
                                                            setOptionsThoiHan([item])
                                                            setThoiHan(item)
                                                            if (item.value.includes("10 máy")) {
                                                                setDrive(10)
                                                            } else {
                                                                setDrive(1)
                                                            }
                                                        } else if (item.name.toLowerCase() == "loại") {
                                                            setOptionLoai([item])
                                                            setLoai(item)
                                                        }
                                                    })

                                                    //setLoai(e.loai)
                                                    setExpired(moment(new Date().addDays(e.expired)).format('YYYY-MM-DD'))
                                                }}
                                                options={optionsTool()}
                                            />
                                        </div>

                                        {
                                            thoiHan
                                                ?
                                                <div className="form-group col-md-4">
                                                    <Label for="drive">Thời hạn</Label>

                                                    <Select
                                                        isDisabled={true}
                                                        className="dropdown-select-index-2"
                                                        isSearchable={false}
                                                        placeholder={thoiHan}
                                                        value={thoiHan}
                                                        onChange={(e) => setThoiHan(e)}
                                                        options={optionsThoiHan}
                                                    />
                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            loai
                                                ?
                                                <div className="form-group col-md-4">
                                                    <Label for="drive">Loại</Label>

                                                    <Select
                                                        isDisabled={true}
                                                        className="dropdown-select-index-3"
                                                        isSearchable={false}
                                                        placeholder={loai}
                                                        value={loai}
                                                        onChange={(e) => setLoai(e)}
                                                        options={optionLoai}
                                                    />
                                                </div>
                                                :
                                                null
                                        }

                                    </div>

                                    <Label style={{ marginTop: "10px" }} for="drive">Số lượng thiết bị</Label>
                                    <Select
                                        className="dropdown-select-index-5"
                                        isSearchable={false}
                                        placeholder={drive}
                                        value={drive}
                                        onChange={(e) => setDrive(e.value)}
                                        options={optionsMaMay()}
                                    />


                                    <Label style={{ marginTop: "20px" }} for="expired">Hạn sử dụng</Label>
                                    <Input
                                        className="d-block d-sm-none"
                                        type="date"
                                        onChange={(e) => setExpired(e.target.value)}
                                        value={expired}
                                        id="expired"
                                    />

                                    <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "-10px" }}>
                                        <Input
                                            className="d-none d-sm-block"
                                            style={{ height: "38px", marginTop: "10px" }}
                                            onClick={() => {
                                                if (activeCalendar) {
                                                    setActiveCalendar(false)
                                                } else {
                                                    setActiveCalendar(true)
                                                }
                                            }}
                                            autocomplete="off"
                                            onChange={(e) => setExpired(e.target.value)}
                                            value={expired}
                                            id="expired"
                                        />
                                        <div className="input-group-append" style={{ marginTop: "0px" }}>
                                            <button style={{ zIndex: '1' }} className="btn btn-info" type="button" onClick={() => {
                                                const date = new Date(expired)
                                                setExpired(moment(date.setMonth(date.getMonth() + 1)).format('YYYY-MM-DD'))
                                            }}>+1 Tháng</button>

                                            <button className="btn btn-info" type="button" onClick={() => {
                                                const date = new Date(expired)
                                                setExpired(moment(date.setMonth(date.getMonth() + 3)).format('YYYY-MM-DD'))
                                            }}>+3 Tháng</button>

                                            <button className="btn btn-info" type="button" onClick={() => {
                                                const date = new Date(expired)
                                                setExpired(moment(date.setFullYear(date.getFullYear() + 1)).format('YYYY-MM-DD'))
                                            }}>+1 Năm</button>
                                            <button className="btn btn-info" type="button" onClick={() => {
                                                const date = new Date(expired)
                                                setExpired(moment(date.setFullYear(date.getFullYear() + 10)).format('YYYY-MM-DD'))
                                            }}>Vĩnh viễn</button>
                                        </div>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        {
                                            activeCalendar
                                                ?
                                                <>
                                                    <Calendar
                                                        onChange={(e) => { selectACalendar(e) }}
                                                        value={calendar}
                                                    />
                                                </>
                                                :
                                                null
                                        }
                                    </div>


                                </FormGroup>
                            </CardBody>

                            <CardFooter style={{ marginTop: "10px" }}>
                                <div >
                                    <Button className='btn btn-primary' style={{ marginRight: "10px" }} onClick={() => createUser()}>Tạo tài khoản {showload ? <span class="spinner-border spinner-border-sm"></span> : null}  </Button>
                                    <Button className='btn btn-success' style={{ marginRight: "10px" }} onClick={() => createUserMail()}>Tạo và gửi mail {showload ? <span class="spinner-border spinner-border-sm"></span> : null}  </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    :
                    <>
                        <Col lg="7" md="7" sm="7">
                            <Card style={{ padding: '15px' }} >
                                <CardHeader>
                                    <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Gia hạn tài khoản</Label>
                                </CardHeader>

                                <CardBody>
                                    <FormGroup>
                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <Label for="fullname">Họ tên</Label>
                                                <Input
                                                    value={fullName}
                                                    id="fullname"
                                                    onChange={(e) => setFullName(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-5">
                                                <Label for="account">Facebook URL</Label>
                                                <Input
                                                    value={facebook}
                                                    id="facebook"
                                                    onChange={(e) => setFacebook(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <Label for="account">Số điện thoại</Label>
                                                <Input
                                                    value={phonenumber}
                                                    id="phonenumber"
                                                    onChange={(e) => setPhonenumber(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-row" style={{ marginTop: "10px" }}>
                                            <div className="form-group col-md-4">
                                                <Label for="email">Email</Label>
                                                <Input
                                                    value={email}
                                                    id="email"
                                                    onChange={(e) => {
                                                        setEmail(e.target.value)
                                                        var user = e.target.value
                                                        if (user.includes("@")) {
                                                            var arr = user.split('@')
                                                            setAccount(arr[0])
                                                        } else {
                                                            setAccount(user)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <Label for="account">Tài khoản</Label>
                                                <Input
                                                    value={account}
                                                    id="account"
                                                    onChange={(e) => setAccount(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <Label for="password">Mật khẩu</Label>
                                                <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "0px" }}>

                                                    <Input
                                                        style={{ height: "38px" }}
                                                        value={password}
                                                        id="password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <div className="input-group-append">
                                                        <button style={{ marginTop: "0px" }} className="btn btn-info" type="button" onClick={() => setPassword(Math.random().toString(36).slice(-8))}>Ngẫu nhiên</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="form-group col-md-4">
                                                <Label for="tool">Sản phẩm</Label>

                                                <Select
                                                    isDisabled={true}
                                                    className="dropdown-select-index-1"
                                                    isSearchable={false}
                                                    placeholder={product}
                                                    value={product}
                                                />
                                            </div>

                                            {
                                                thoiHan
                                                    ?
                                                    <div className="form-group col-md-4">
                                                        <Label for="drive">Thời hạn</Label>

                                                        <Select
                                                            isDisabled={true}
                                                            className="dropdown-select-index-2"
                                                            isSearchable={false}
                                                            placeholder={thoiHan}
                                                            value={thoiHan}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                loai
                                                    ?
                                                    <div className="form-group col-md-4">
                                                        <Label for="drive">Loại</Label>

                                                        <Select
                                                            isDisabled={true}
                                                            className="dropdown-select-index-3"
                                                            isSearchable={false}
                                                            placeholder={loai}
                                                            value={loai}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                            }

                                        </div>

                                        <Label style={{ marginTop: "10px" }} for="drive">Số lượng thiết bị</Label>
                                        <Select
                                            isDisabled={true}
                                            className="dropdown-select-index-5"
                                            isSearchable={false}
                                            placeholder={drive}
                                            value={drive}
                                        />


                                        <Label style={{ marginTop: "20px" }} for="expired">Hạn sử dụng</Label>
                                        <Input
                                            className="d-block d-sm-none"
                                            type="date"
                                            onChange={(e) => setExpired(e.target.value)}
                                            value={expired}
                                            id="expired"
                                        />

                                        <div className="input-group" style={{ height: "38px", paddingTop: "0px", marginTop: "-10px" }}>
                                            <Input
                                                className="d-none d-sm-block"
                                                style={{ height: "38px", marginTop: "10px" }}
                                                onClick={() => {
                                                    if (activeCalendar) {
                                                        setActiveCalendar(false)
                                                    } else {
                                                        setActiveCalendar(true)
                                                    }
                                                }}
                                                autocomplete="off"
                                                onChange={(e) => setExpired(e.target.value)}
                                                value={expired}
                                                id="expired"
                                            />
                                            <div className="input-group-append" style={{ marginTop: "0px" }}>
                                                <button style={{ zIndex: '1' }} className="btn btn-info" type="button" onClick={() => {
                                                    const date = new Date(expired)
                                                    setExpired(moment(date.setMonth(date.getMonth() + 1)).format('YYYY-MM-DD'))
                                                }}>+1 Tháng</button>

                                                <button className="btn btn-info" type="button" onClick={() => {
                                                    const date = new Date(expired)
                                                    setExpired(moment(date.setMonth(date.getMonth() + 3)).format('YYYY-MM-DD'))
                                                }}>+3 Tháng</button>

                                                <button className="btn btn-info" type="button" onClick={() => {
                                                    const date = new Date(expired)
                                                    setExpired(moment(date.setFullYear(date.getFullYear() + 1)).format('YYYY-MM-DD'))
                                                }}>+1 Năm</button>
                                                <button className="btn btn-info" type="button" onClick={() => {
                                                    const date = new Date(expired)
                                                    setExpired(moment(date.setFullYear(date.getFullYear() + 10)).format('YYYY-MM-DD'))
                                                }}>Vĩnh viễn</button>
                                            </div>
                                        </div>

                                        <div style={{ marginTop: "10px" }}>
                                            {
                                                activeCalendar
                                                    ?
                                                    <>
                                                        <Calendar
                                                            onChange={(e) => { selectACalendar(e) }}
                                                            value={calendar}
                                                        />
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>


                                    </FormGroup>
                                </CardBody>

                                <CardFooter style={{ marginTop: "10px" }}>
                                    <div >
                                        <Button className='btn btn-warning' style={{ marginRight: "10px" }} onClick={() => updateUser()}>Sửa tài khoản {showload ? <span class="spinner-border spinner-border-sm"></span> : null}  </Button>
                                    </div>
                                </CardFooter>
                            </Card>
                        </Col>
                    </>
                }


            </Row>
        </div >

    )
}

export default OrderDetails