import React, { useState } from "react"
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import MembersService from "services/members.service"
import { ValidateFormMember } from "Validate/ValidateForm"
import {
    FormGroup,
    Label,
    Input
} from "reactstrap";

function CreateMenber() {
    const [account, setAccount] = useState('')
    const [email, setEmail] = useState('')
    const [fullName, setFullName] = useState('')
    const [password, setPassword] = useState('')

    function createMember() {
        const validate = ValidateFormMember(memberInformationWillCreate())
        if(validate){
            return
        }
        Swal.fire({
            title: 'Xác nhận',
            text: 'Bạn có muốn thêm tài khoản này?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Có',
            cancelButtonText: 'Không'
        }).then((result) => {
            if (result.value) {
                MembersService.createMember(memberInformationWillCreate())
                    .then(response => {
                        Swal.fire({
                            title: 'Thông báo',
                            text: response.data.message,
                            type: 'message',
                        }).then(res => {
                            res.isConfirmed ? window.location.reload() : window.location.reload()
                        })
                    }, error => {
                        Swal.fire({
                            title: 'Thông báo',
                            text: error.message,
                            type: 'error',
                        })
                    })
            };
        })
    }

    function memberInformationWillCreate() {
        return {
            "username": account,
            "password": password,
            "email": email,
            "fullname": fullName,
        }
    }

    return (
        <div className = "col col-lg-3">
            <FormGroup>
                <Label style={{ marginTop: "0.5rem" }} for="account">Account</Label>
                <Input
                    value={account}
                    id="account"
                    onChange={(e) => setAccount(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="password">Password</Label>
                <Input
                    value={password}
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="email">Email</Label>
                <Input
                    value={email}
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Label style={{ marginTop: "0.5rem" }} for="fullname">Full name</Label>
                <Input
                    value={fullName}
                    id="fullname"
                    onChange={(e) => setFullName(e.target.value)}
                />
            </FormGroup>
            <button className="btn btn-primary" onClick={() => createMember()}>Thêm</button>
        </div>
    )
}

export default CreateMenber