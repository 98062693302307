import axios from 'axios';
import authHeader from './auth-header';

const API_URL = "https://api.dhbtools.com/app/";

class LanguageService {

    getLanguage(tool) {
        return axios.get(`${API_URL}language?tool=${tool}`, { headers: authHeader() });
    }

    createLanguage(tool, key, vi, en) {
        return axios.post(`${API_URL}language?tool=${tool}&key=${key}&vi=${vi}&en=${en}`, null, { headers: authHeader() });
    }

    updateLanguage(tool, key, vi, en) {
        return axios.put(`${API_URL}language?tool=${tool}&key=${key}&vi=${vi}&en=${en}`, null, { headers: authHeader() });
    }

    deleteLanguage(tool, key) {
        return axios.delete(`${API_URL}language?tool=${tool}&key=${key}`, { headers: authHeader() });
    }

    commitLanguage(tool) {
        return axios.get(`${API_URL}language/commit?tool=${tool}`, { headers: authHeader() });
    }
}

export default new LanguageService();
